<template>
  <div>
    <v-divider></v-divider>
    <v-layout wrap fill-height v-if="appUser === 'range'">
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-1 align-self-center>
              <v-img
                contain
                width="100%"
                height="90%"
                src="../assets/sidelogo.png"
                fill-height
              ></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-layout wrap justify-center>
            <v-flex xs12 md6 sm6 lg6 align-self-center class="text-center">
              <v-btn
                depressed
                dark
                :color="activeSection == 'hwc' ? 'red' : ''"
                @click="setActiveSection('hwc')"
                >HWC</v-btn
              >
            </v-flex>
            <v-flex xs12 md6 sm6 lg6 class="text-center">
              <v-btn
                depressed
                dark
                :color="activeSection == 'sarpa' ? 'red' : ''"
                @click="setActiveSection('sarpa')"
                >SARPA</v-btn
              >
            </v-flex>
          </v-layout>

          <v-list dense nav v-if="activeSection === 'hwc'">
            <v-list-item
              v-for="item in rangeitems"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="activeSection === 'sarpa'">
            <v-list-item
              v-for="item in sarpadashboard"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>

            <!-- <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ sarpa.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="sarpa.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in sarpa.sarpaItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group> -->
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0 v-if="activeSection === 'hwc'">
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
            lg12
            pb-0
            mb-0
            v-if="activeSection === 'sarpa'"
          >
            <SarpaAppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap fill-height v-else-if="appUser === 'division'">
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-1 align-self-center>
              <v-img
                contain
                width="100%"
                height="90%"
                src="../assets/sidelogo.png"
                fill-height
              ></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-layout wrap justify-center>
            <v-flex xs12 md6 sm6 lg6 align-self-center class="text-center">
              <v-btn
                depressed
                dark
                :color="activeSection == 'hwc' ? 'red' : ''"
                @click="setActiveSection('hwc')"
                >HWC</v-btn
              >
            </v-flex>
            <v-flex xs12 md6 sm6 lg6 class="text-center">
              <v-btn
                depressed
                dark
                :color="activeSection == 'sarpa' ? 'red' : ''"
                @click="setActiveSection('sarpa')"
                >SARPA</v-btn
              >
            </v-flex>
          </v-layout>

          <v-list dense nav v-if="activeSection === 'hwc'">
            <v-list-item
              v-for="item in divisionitems"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="activeSection === 'sarpa'">
            <v-list-item
              v-for="item in sarpadashboard"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>

            <!-- <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ sarpa.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="sarpa.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in sarpa.sarpaItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group> -->
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0 v-if="activeSection === 'hwc'">
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
            lg12
            pb-0
            mb-0
            v-if="activeSection === 'sarpa'"
          >
            <SarpaAppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap fill-height v-else-if="appUser === 'circle'">
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-1 align-self-center>
              <v-img
                contain
                width="100%"
                height="90%"
                src="../assets/sidelogo.png"
                fill-height
              ></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-layout wrap justify-center>
            <v-flex xs12 md6 sm6 lg6 align-self-center class="text-center">
              <v-btn
                depressed
                dark
                :color="activeSection == 'hwc' ? 'red' : ''"
                @click="setActiveSection('hwc')"
                >HWC</v-btn
              >
            </v-flex>
            <v-flex xs12 md6 sm6 lg6 class="text-center">
              <v-btn
                depressed
                dark
                :color="activeSection == 'sarpa' ? 'red' : ''"
                @click="setActiveSection('sarpa')"
                >SARPA</v-btn
              >
            </v-flex>
          </v-layout>

          <v-list dense nav v-if="activeSection === 'hwc'">
            <v-list-item
              v-for="item in circleitems"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-list dense nav v-if="activeSection === 'sarpa'">
            <v-list-item
              v-for="item in sarpadashboard"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>

            <!-- <v-list-group class="pt-0 mt-0" no-action>
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3">{{ sarpa.icon }}</v-icon>
                  <v-list-item-title
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="sarpa.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in sarpa.sarpaItems"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <v-list-item-title
                    class="pr-0"
                    style="font-size: 14px; font-family: poppinsmedium"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group> -->
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0 v-if="activeSection === 'hwc'">
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
            lg12
            pb-0
            mb-0
            v-if="activeSection === 'sarpa'"
          >
            <SarpaAppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap fill-height v-else-if="appUser === 'RRT'">
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-1 align-self-center>
              <v-img
                contain
                width="100%"
                height="90%"
                src="../assets/sidelogo.png"
                fill-height
              ></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-list dense nav>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap fill-height v-else-if="appUser === 'DEOC'">
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-1 align-self-center>
              <v-img
                contain
                width="100%"
                height="90%"
                src="../assets/sidelogo.png"
                fill-height
              ></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-list dense nav>
            <v-list-item
              v-for="item in seocanddeoc"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap fill-height v-else>
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-1 align-self-center>
              <v-img
                contain
                width="100%"
                height="90%"
                src="../assets/sidelogo.png"
                fill-height
              ></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-list dense nav>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="font-size: 14px; font-family: poppinsmedium"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppHeader from "@/components/Common/appHeader";
import SarpaAppHeader from "@/components/Common/appHeadersarpa";
export default {
  components: {
    AppHeader,
    SarpaAppHeader,
  },
  data() {
    return {
      activeSection: localStorage.getItem("activeSection") || "hwc",
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Officers/closedcases",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        {
          title: "Report Case",
          icon: "mdi-file-eye",
          path: "/Officers/reportcase",
        },
      ],
      seocanddeoc: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Officers/closedcases",
        },
        {
          title: "Report Case",
          icon: "mdi-file-eye",
          path: "/Officers/reportcase",
        },
        {
          title: "Daily Report",
          icon: "mdi-file-pdf-box",
          path: "/Officers/daily-report",
        },
        
      ],
      rangeitems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Officers/closedcases",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        {
          title: "Report Case",
          icon: "mdi-file-eye",
          path: "/Officers/reportcase",
        },
      ],
      divisionitems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Officers/closedcases",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        {
          title: "Report Case",
          icon: "mdi-file-eye",
          path: "/Officers/reportcase",
        },
        {
          title: "DEOC USER",
          icon: "mdi-account-outline",
          path: "/Officers/adddeoc",
        },
        // {
        //   title: "SEOC USER",
        //   icon: "mdi-account",
        //   path: "/Officers/addSeoc",
        // },
      ],
      circleitems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Officers/closedcases",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        // { title: "Report Case", icon: "mdi-file-eye", path: "/Officers/reportcase" },
      ],
      sarpadashboard: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/officers/sarpadashboard",
        },
        // {
        //   title: "Statistics ",
        //   icon: "mdi-chart-bar",
        //   path: "/admin/commonanalyticspage",
        // },
        // {
        //   title: "Add Snakes",
        //   icon: "mdi-plus",
        //   path: "/addSnakes",
        // },
        // {
        //   title: "All Users",
        //   icon: "mdi-account-multiple",
        //   path: "/usersList",
        // },
        // {
        //   title: "New Rescuers",
        //   icon: "mdi-file-plus",
        //   path: "/newrescuerslist",
        // },
        // {
        //   title: "Rescuer List",
        //   icon: "mdi-format-list-bulleted",
        //   path: "/allrescuerlist",
        // },
        {
          title: "Pending Operations",
          icon: "mdi-receipt-clock-outline",
          path: "/officers/pendingoperations",
        },
        {
          title: "Assigned Operations",
          icon: "mdi-clipboard-check",
          path: "/officers/assignedoperations",
        },
        {
          title: "Rescued Operations",
          icon: "mdi-invoice-list",
          path: "/officers/rescuedoperations",
        },
        {
          title: "Released Operations",
          icon: "mdi-view-list",
          path: "/officers/releasedoperations",
        },
        {
          title: "Approved Operations",
          icon: "mdi-playlist-check",
          path: "/officers/approvedoperations",
        },
        // {
        //   title: "Delete Operations",
        //   icon: "mdi-delete-alert",
        //   path: "/officers/deleteoperations",
        // },
        // {
        //   title: "Training List",
        //   icon: "mdi-chair-school",
        //   path: "/officers/traininglist",
        // },
        {
          title: "Snake Reports",
          icon: "mdi-file-chart",
          path: "/officers/snakereports",
        },
        // {
        //   title: "Add Snake Experts",
        //   icon: "mdi-plus",
        //   path: "/addsnakeexperts",
        // },
        // {
        //   title: "Add Hospital",
        //   icon: "mdi-hospital-building",
        //   path: "/addhospital",
        // },
        {
          title: "View Hospital",
          icon: "mdi-hospital-marker",
          path: "/officers/Viewhospital",
        },
        {
          title: "Identification Help",
          icon: "mdi-help-box-multiple-outline",
          path: "/officers/identificationhelp",
        },
        {
          title: "Excel",
          icon: "mdi-file-excel",
          path: "/officers/excelreport",
        },
        // {
        //   title: "Approve Hospital",
        //   icon: "mdi-check-decagram-outline",
        //   path: "/approvehospital",
        // },
      ],
      right: null,
    };
  },
  methods: {
    setActiveSection(section) {
      if (section === "hwc") {
        this.$router.push("/Officers/analytics");
      }
      if (section === "sarpa") {
        this.$router.push("/officers/sarpadashboard");
      }
      this.activeSection = section;
      localStorage.setItem("activeSection", section); // Save to localStorage
    },
  },
  computed: {
    appUser() {
      return this.$store.state.userRole;
    },
  },
};
</script>